<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import {
  Laptop,
  Smartphone,
  Globe,
  ChevronRight,
  Github,
  Linkedin,
  Mail,
  Code,
  Database,
  Layout,
  Cpu
} from 'lucide-vue-next'
import Button from './ui/button.vue'
import Card from './ui/card.vue'
import CardContent from './ui/card-content.vue'
import CardDescription from './ui/card-description.vue'
import CardFooter from './ui/card-footer.vue'
import CardHeader from './ui/card-header.vue'
import CardTitle from './ui/card-title.vue'

const router = useRouter()
const selectedCategory = ref('All')

const projects = [
  {
    id: 1,
    name: 'Intervl',
    description: 'An interval timer iOS app',
    category: 'Mobile',
    image: '/images/Hourglass.jpg',
    route: '/intervl'
  },
]

const filteredProjects = computed(() =>
  selectedCategory.value === 'All'
    ? projects
    : projects.filter(project => project.category === selectedCategory.value)
)

const navigateToProject = (route) => {
  window.scrollTo({ top: 0, behavior: 'instant' })
  router.push(route)
}

const scrollToAbout = () => {
  document.getElementById('skills')?.scrollIntoView({ behavior: 'smooth' })
}

const scrollToProjects = () => {
  document.getElementById('projects')?.scrollIntoView({ behavior: 'smooth' })
}

const skills = [
  {
    icon: Code,
    title: 'Web Applications',
    description: 'Powerful web-based tools that solve real-world problems'
  },
  {
    icon: Database,
    title: 'Mobile Apps',
    description: 'Intuitive mobile experiences that users love'
  },
  {
    icon: Layout,
    title: 'User Experience',
    description: 'Beautiful interfaces that make complex tasks simple'
  },
  {
    icon: Cpu,
    title: 'Innovation',
    description: 'Cutting-edge technology solving modern challenges'
  }
]
</script>

<template>
  <div class="min-h-screen bg-background text-foreground">
    <header
      class="sticky top-0 z-40 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div class="container flex h-16 items-center">
        <nav class="flex gap-6">
          <a href="#" class="flex items-center space-x-2">
            <span
              class="text-xl font-bold bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text">Jinnius</span>
          </a>
          <a href="#about"
            class="flex items-center text-sm font-medium text-muted-foreground hover:text-primary transition-colors">
            About
          </a>
          <a href="#projects"
            class="flex items-center text-sm font-medium text-muted-foreground hover:text-primary transition-colors">
            Projects
          </a>
          <a href="#skills"
            class="flex items-center text-sm font-medium text-muted-foreground hover:text-primary transition-colors">
            Skills
          </a>
          <a href="#contact"
            class="flex items-center text-sm font-medium text-muted-foreground hover:text-primary transition-colors">
            Contact
          </a>
        </nav>
      </div>
    </header>

    <main class="flex-1">
      <section id="about"
        class="w-full py-12 md:py-24 lg:py-32 xl:py-48 relative overflow-hidden bg-gradient-to-b from-background via-background to-muted">
        <div class="absolute inset-0">
          <div
            class="absolute -right-1/4 top-1/4 w-1/2 h-1/2 bg-gradient-to-b from-blue-500/5 via-purple-500/5 to-background rounded-full blur-3xl">
          </div>
          <div
            class="absolute -left-1/4 bottom-1/4 w-1/2 h-1/2 bg-gradient-to-t from-blue-500/5 via-purple-500/5 to-background rounded-full blur-3xl">
          </div>
          <div
            class="absolute left-1/3 top-1/4 w-1/3 h-1/3 bg-gradient-to-r from-blue-500/5 to-purple-500/5 rounded-full blur-3xl">
          </div>
        </div>
        <div class="container px-4 md:px-6 relative">
          <div class="flex flex-col lg:flex-row items-center gap-8 lg:gap-12">
            <div class="flex flex-col items-center lg:items-start space-y-6 text-center lg:text-left lg:w-2/3" v-motion
              :initial="{ opacity: 0, x: -50 }" :enter="{ opacity: 1, x: 0 }" :transition="{ duration: 500 }">
              <h1 class="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl lg:text-7xl/none">
                <span class="bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text">
                  Software Products That Matter
                </span>
              </h1>
              <p class="mx-auto lg:mx-0 max-w-[700px] text-xl text-muted-foreground">
                Jinnius creates innovative digital products that make life better.
                From productivity tools to mobile apps, we're building the future one product at a time.
              </p>
              <div class="flex flex-col sm:flex-row gap-4">
                <Button class="w-full group" @click="scrollToProjects">
                  <span>View Products</span>
                  <ChevronRight class="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                </Button>
                <Button variant="outline" class="w-full" @click="scrollToAbout">
                  <span>Learn More</span>
                </Button>
              </div>
            </div>
            <div class="lg:w-1/3 h-full" v-motion :initial="{ opacity: 0, x: 50 }" :enter="{ opacity: 1, x: 0 }"
              :transition="{ duration: 500, delay: 200 }">
            </div>
          </div>
        </div>
      </section>

      <section id="skills" class="w-full py-12 md:py-24 lg:py-32 bg-muted/50">
        <div class="container px-4 md:px-6">
          <h2 class="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-4">What We Do</h2>
          <p class="text-xl text-muted-foreground text-center mb-12 max-w-[800px] mx-auto">
            Creating thoughtful digital products that enhance people's lives
          </p>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div v-for="skill in skills" :key="skill.title" v-motion :initial="{ opacity: 0, y: 50 }"
              :enter="{ opacity: 1, y: 0 }" :transition="{ duration: 500 }">
              <Card class="h-full hover:shadow-lg transition-shadow">
                <CardHeader>
                  <component :is="skill.icon" class="h-12 w-12 mb-4 text-primary" />
                  <CardTitle>{{ skill.title }}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p class="text-muted-foreground">{{ skill.description }}</p>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </section>

      <section id="projects" class="w-full py-12 md:py-24 lg:py-32">
        <div class="container px-4 md:px-6">
          <h2 class="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">Featured Projects
          </h2>
          <div class="flex justify-center space-x-4 mb-12">
            <Button :variant="selectedCategory === 'All' ? 'default' : 'outline'" class="transition-all hover:scale-105"
              @click="selectedCategory = 'All'">
              All
            </Button>
            <Button :variant="selectedCategory === 'Web' ? 'default' : 'outline'" class="transition-all hover:scale-105"
              @click="selectedCategory = 'Web'">
              <Globe class="mr-2 h-4 w-4" />
              Web
            </Button>
            <Button :variant="selectedCategory === 'Mobile' ? 'default' : 'outline'"
              class="transition-all hover:scale-105" @click="selectedCategory = 'Mobile'">
              <Smartphone class="mr-2 h-4 w-4" />
              Mobile
            </Button>
            <Button :variant="selectedCategory === 'Desktop' ? 'default' : 'outline'"
              class="transition-all hover:scale-105" @click="selectedCategory = 'Desktop'">
              <Laptop class="mr-2 h-4 w-4" />
              Desktop
            </Button>
          </div>
          <div class="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <div v-for="project in filteredProjects" :key="project.id" v-motion :initial="{ opacity: 0, y: 50 }"
              :enter="{ opacity: 1, y: 0 }" :transition="{ duration: 500 }">
              <Card class="group hover:shadow-xl transition-all hover:-translate-y-1">
                <CardHeader>
                  <CardTitle class="text-xl group-hover:text-primary transition-colors">{{ project.name }}</CardTitle>
                  <CardDescription>{{ project.category }}</CardDescription>
                </CardHeader>
                <CardContent>
                  <div class="overflow-hidden rounded-md mb-4">
                    <img :src="project.image" :alt="project.name"
                      class="w-full aspect-video object-cover transition-transform group-hover:scale-105" />
                  </div>
                  <p class="text-muted-foreground">{{ project.description }}</p>
                </CardContent>
                <CardFooter>
                  <Button class="w-full group" @click="navigateToProject(project.route)">
                    <span>View Project</span>
                    <ChevronRight class="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                  </Button>
                </CardFooter>
              </Card>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" class="w-full py-12 md:py-24 lg:py-32 border-t bg-muted/50">
        <div class="container px-4 md:px-6">
          <div class="flex flex-col items-center justify-center space-y-4 text-center">
            <h2 class="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Stay Updated</h2>
            <p class="mx-auto max-w-[700px] text-xl text-muted-foreground">
              Be the first to know about our latest products and updates.
            </p>
            <div class="flex space-x-4 mt-8">
              <Button class="w-full group">
                <Mail class="mr-2 h-4 w-4" />
                <span>Subscribe</span>
                <ChevronRight class="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
              </Button>
            </div>
          </div>
        </div>
      </section>
    </main>

    <footer class="w-full py-6 border-t">
      <div class="container px-4 md:px-6">
        <div class="flex flex-col items-center justify-center space-y-4 text-center">
          <p class="text-sm text-muted-foreground">
            {{ new Date().getFullYear() }} Jinnius. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<style scoped>
.read-the-docs {
  color: #888;
}
</style>
