import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import Tickr from '../components/Projects/Tickr.vue'
import PrivacyPolicy from '../components/Projects/components/PrivacyPolicy.vue'
import Intervl from '../intervl/Intervl.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/intervl',
    name: 'Intervl',
    component: Intervl
  },
  {
    path: '/intervl/privacy',
    name: 'Privacy',
    component: PrivacyPolicy
  },
  {
    path: '/tickr',
    name: 'Tickr',
    component: Tickr
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router 