<script setup lang="ts">
import { Star } from 'lucide-vue-next';

const testimonials = [
  {
    name: 'Alex Thompson',
    role: 'Personal Trainer',
    content: 'intervl has revolutionized how I manage my client training sessions. The multiple timer feature is a game-changer.',
    avatar: 'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?auto=format&fit=crop&w=150&q=80'
  },
  {
    name: 'Maria Santos',
    role: 'Fitness Enthusiast',
    content: "The rounds and sets feature is perfect for my HIIT workouts. I can't imagine training without it now.",
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=150&q=80'
  },
  {
    name: 'David Chen',
    role: 'CrossFit Coach',
    content: "Clean interface, reliable performance, and the dark mode is perfect for early morning sessions.",
    avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&w=150&q=80'
  }
];
</script>

<template>
  <section id="testimonials" class="py-20">
    <div class="container mx-auto px-6">
      <div class="text-center mb-16">
        <h2 class="text-4xl font-bold text-gray-900 mb-4">What Our Users Say</h2>
        <p class="text-xl text-gray-600">Join thousands of satisfied athletes and trainers</p>
      </div>
      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div v-for="testimonial in testimonials" :key="testimonial.name"
          class="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition">
          <div class="flex items-center space-x-1 mb-4">
            <Star v-for="i in 5" :key="i" class="w-5 h-5 text-[#488FF2] fill-current" />
          </div>
          <p class="text-gray-600 mb-6">{{ testimonial.content }}</p>
          <div class="flex items-center">
            <img :src="testimonial.avatar" :alt="testimonial.name" class="w-12 h-12 rounded-full mr-4" />
            <div>
              <h4 class="font-semibold text-gray-900">{{ testimonial.name }}</h4>
              <p class="text-gray-600 text-sm">{{ testimonial.role }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>