<script setup lang="ts">
import { Timer, Repeat, Layout, Moon } from 'lucide-vue-next';

const features = [
  {
    icon: Timer,
    title: 'Multiple Timers',
    description: 'Create and run multiple timers simultaneously for complex training sessions.'
  },
  {
    icon: Repeat,
    title: 'Rounds & Sets',
    description: 'Configure rounds and sets for interval training and structured workouts.'
  },
  {
    icon: Layout,
    title: 'Clean Interface',
    description: 'Intuitive design that makes timer management effortless and enjoyable.'
  },
  {
    icon: Moon,
    title: 'Dark Mode',
    description: 'Easy on the eyes with both light and dark themes for any environment.'
  }
];
</script>

<template>
  <section id="features" class="bg-gray-50 py-20">
    <div class="container mx-auto px-6">
      <div class="text-center mb-16">
        <h2 class="text-4xl font-bold text-gray-900 mb-4">Why Choose intervl?</h2>
        <p class="text-xl text-gray-600">The most versatile timer app for all your needs</p>
      </div>
      <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div v-for="feature in features" :key="feature.title"
          class="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition">
          <component :is="feature.icon" class="w-12 h-12 text-[#488FF2] mb-4" />
          <h3 class="text-xl font-semibold text-gray-900 mb-2">{{ feature.title }}</h3>
          <p class="text-gray-600">{{ feature.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>