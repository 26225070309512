<script setup lang="ts">
import { Timer, Twitter, Instagram } from 'lucide-vue-next';

const currentYear = new Date().getFullYear();
</script>

<template>
  <footer class="bg-gray-900 text-white py-12">
    <div class="container mx-auto px-6">
      <div class="grid md:grid-cols-4 gap-8 mb-8">
        <div class="col-span-2 md:col-span-1">
          <div class="flex items-center space-x-2 mb-4">
            <Timer class="w-8 h-8 text-[#488FF2]" />
            <span class="text-xl font-bold">intervl</span>
          </div>
          <p class="text-gray-400">
            Your ultimate timer companion for precise training and workouts.
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-4">Quick Links</h3>
          <ul class="space-y-2 text-gray-400">
            <li><a href="#features" class="hover:text-[#488FF2] transition">Features</a></li>
            <li><a href="#testimonials" class="hover:text-[#488FF2] transition">Testimonials</a></li>
            <li><a href="#download" class="hover:text-[#488FF2] transition">Download</a></li>
          </ul>
        </div>

        <div>
          <h3 class="font-semibold mb-4">Legal</h3>
          <ul class="space-y-2 text-gray-400">
            <li><a href="#" class="hover:text-[#488FF2] transition">Privacy Policy</a></li>
            <li><a href="#" class="hover:text-[#488FF2] transition">Terms of Service</a></li>
          </ul>
        </div>

        <div>
          <h3 class="font-semibold mb-4">Connect</h3>
          <div class="flex space-x-4">
            <a href="#" class="text-gray-400 hover:text-[#488FF2] transition">
              <Twitter class="w-6 h-6" />
            </a>
            <a href="#" class="text-gray-400 hover:text-[#488FF2] transition">
              <Instagram class="w-6 h-6" />
            </a>
          </div>
        </div>
      </div>

      <div class="pt-8 border-t border-gray-800 text-center text-gray-400">
        <p>&copy; {{ currentYear }} intervl. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>