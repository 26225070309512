<template>
  <div class="intervl-app">
    <div v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }">
      <HeroSection v-bind="heroContent" />
    </div>
    <div v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }" :delay="200">
      <FeaturesSection v-bind="featuresContent" />
    </div>
    <div v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }" :delay="300">
      <UseCasesSection v-bind="useCasesContent" />
    </div>
    <div v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }" :delay="400">
      <AboutSection v-bind="aboutContent" />
    </div>
    <div v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }" :delay="500">
      <ClientsSection v-bind="clientsContent" />
    </div>
    <div v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }" :delay="600">
      <TestimonialsSection v-bind="testimonialsContent" />
    </div>
    <div v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }" :delay="700">
      <FaqSection v-bind="faqContent" />
    </div>
    <div v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }" :delay="800">
      <NewsletterSection v-bind="newsletterContent" />
    </div>
    <!-- <div v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }" :delay="900">
      <DownloadSection v-bind="downloadContent" />
    </div> -->
    
    <div class="py-8 text-center text-sm text-gray-500">
      <router-link to="/intervl/privacy" class="hover:text-gray-700">Privacy Policy</router-link>
    </div>
  </div>
</template>

<script setup>
import HeroSection from './components/HeroSection.vue'
import FeaturesSection from './components/FeaturesSection.vue'
import UseCasesSection from './components/UseCasesSection.vue'
import TestimonialsSection from './components/TestimonialsSection.vue'
import FaqSection from './components/FaqSection.vue'
import DownloadSection from './components/DownloadSection.vue'
import NewsletterSection from './components/NewsletterSection.vue'
import ClientsSection from './components/ClientsSection.vue'
import AboutSection from './components/AboutSection.vue'
import {
  Clock as ClockIcon,
  Paintbrush as PaintbrushIcon,
  Layers as LayersIcon,
  Dumbbell as DumbbellIcon,
  Utensils as UtensilsIcon,
  Check as CheckIcon
} from 'lucide-vue-next'

const heroContent = {
  title: 'Intervl',
  description: 'Your Ultimate Interval Timer. Elevate your workouts and perfect your cooking with precision timing that adapts to your lifestyle. Beautiful, intuitive, and powerful - exactly when you need it.',
  backgroundClass: 'bg-gradient-to-br from-orange-500 via-rose-500 to-pink-600',
  ctaButtons: [
    {
      text: '',
      href: '#',
      class: 'hover:opacity-90 transition-opacity transform hover:scale-105 duration-200',
      icon: '/images/app-store.svg',
      iconClass: 'h-14 w-auto'
    }
  ]
}

const featuresContent = {
  title: 'Powerful Features',
  description: 'Everything you need to master your time with elegance and precision',
  features: [
    {
      title: 'Smart Intervals',
      description: 'Create, save, and share custom interval patterns with intelligent presets for different activities',
      icon: ClockIcon,
      bgClass: 'bg-orange-50',
      iconClass: 'text-orange-600'
    },
    {
      title: 'Beautiful Interface',
      description: 'Stunning, minimalist design with fluid animations and haptic feedback for an immersive experience',
      icon: PaintbrushIcon,
      bgClass: 'bg-rose-50',
      iconClass: 'text-rose-600'
    },
    {
      title: 'Multiple Timers',
      description: 'Run multiple timers simultaneously with smart notifications and voice commands for complex routines',
      icon: LayersIcon,
      bgClass: 'bg-pink-50',
      iconClass: 'text-pink-600'
    },
    {
      title: 'Progress Tracking',
      description: 'Track your progress over time with detailed analytics and beautiful visualizations',
      icon: CheckIcon,
      bgClass: 'bg-orange-50',
      iconClass: 'text-orange-600'
    }
  ]
}

const useCasesContent = {
  title: 'Perfect For Every Need',
  description: 'Discover how Intervl transforms your daily activities with intelligent timing',
  cases: [
    {
      title: 'Fitness & Workouts',
      description: 'Transform your training with precision timing and smart workout tracking.',
      icon: DumbbellIcon,
      bgClass: 'bg-gradient-to-br from-orange-100 to-rose-100',
      iconClass: 'text-orange-600',
      benefits: [
        'HIIT and circuit training optimization',
        'Smart rest period management',
        'AI-powered workout suggestions',
        'Progress tracking with insights',
        'Voice-guided transitions'
      ]
    },
    {
      title: 'Cooking & Food Prep',
      description: 'Master the art of timing in your kitchen with precision and elegance.',
      icon: UtensilsIcon,
      bgClass: 'bg-gradient-to-br from-rose-100 to-pink-100',
      iconClass: 'text-pink-600',
      benefits: [
        'Multi-dish synchronization',
        'Recipe integration & sharing',
        'Smart kitchen workflow',
        'Temperature-based timing',
        'Voice control while cooking'
      ]
    }
  ]
}

const testimonialsContent = {
  title: 'Loved by Users Worldwide',
  description: 'Join thousands of satisfied users who have transformed their timing experience with Intervl',
  testimonials: [
    {
      text: 'Intervl has completely transformed my HIIT workouts. The smart intervals and voice guidance keep me focused, while the beautiful interface makes every session a joy. It\'s like having a personal trainer in my pocket!',
      author: 'Sarah M.',
      role: 'Fitness Coach & Athlete',
      image: '/images/testimonials/sarah.jpg',
      rating: 5
    },
    {
      text: 'As a professional chef, timing is everything. Intervl\'s multi-timer system and recipe integration have revolutionized my kitchen workflow. The voice control is a game-changer when my hands are busy.',
      author: 'James K.',
      role: 'Professional Chef',
      image: '/images/testimonials/james.jpg',
      rating: 5
    },
    {
      text: 'The attention to detail in Intervl is incredible. From the smooth animations to the haptic feedback, everything feels premium. It\'s the perfect blend of functionality and beauty.',
      author: 'Emily R.',
      role: 'UX Designer & Fitness Enthusiast',
      image: '/images/testimonials/emily.jpg',
      rating: 5
    }
  ]
}

const faqContent = {
  title: 'Frequently Asked Questions',
  description: 'Get answers to common questions about Intervl',
  faqs: [
    {
      question: 'Can I create and share custom interval patterns?',
      answer: 'Yes! Intervl allows you to create, save, and share custom interval patterns. You can also import patterns from other users and modify them to suit your needs. Our smart presets make it easy to get started with optimized patterns for different activities.'
    },
    {
      question: 'Does it work offline?',
      answer: 'Absolutely! Once downloaded, Intervl works completely offline. Your timers, patterns, and settings are stored locally. Internet connection is only needed for syncing across devices and sharing patterns.'
    },
    {
      question: 'How does voice control work?',
      answer: 'Intervl features advanced voice control that works seamlessly with both workout and cooking scenarios. Simply enable voice commands in settings and use natural language to start, pause, or switch timers - perfect for when your hands are busy.'
    },
    {
      question: 'Is my progress data secure?',
      answer: 'Yes, your privacy and data security are our top priorities. All your progress data is encrypted and stored securely. You can choose to keep it local or sync across devices using end-to-end encryption.'
    }
  ]
}

const aboutContent = {
  title: 'The Story Behind Intervl',
  description: 'We believe timing should be both beautiful and functional. Our journey started with a simple idea: to create the most elegant and intuitive interval timer that adapts to your needs.',
  image: '/intervl-about.png',
  features: [
    {
      title: 'Our Mission',
      description: 'To transform how people manage time in their daily activities through beautiful and intelligent design.'
    },
    {
      title: 'The Team',
      description: 'A passionate group of designers and developers dedicated to creating the perfect timing experience.'
    },
    {
      title: 'Our Values',
      description: 'We believe in beautiful design, intuitive functionality, and putting our users first in everything we do.'
    }
  ],
  backgroundClass: 'bg-gradient-to-br from-orange-50 to-pink-50'
}

const clientsContent = {
  title: 'Trusted By Professionals',
  description: 'From fitness studios to professional kitchens, Intervl is the choice of experts',
  clients: [
    {
      name: 'FitLife Studios',
      logo: '/images/clients/fitlife.png'
    },
    {
      name: 'Culinary Institute',
      logo: '/images/clients/culinary.png'
    },
    {
      name: 'CrossFit Elite',
      logo: '/images/clients/crossfit.png'
    },
    {
      name: 'Gourmet Kitchen',
      logo: '/images/clients/gourmet.png'
    }
  ]
}

const newsletterContent = {
  title: 'Stay Updated with Intervl',
  description: 'Get exclusive tips, feature updates, and special offers directly in your inbox',
  backgroundClass: 'bg-gradient-to-br from-orange-500 via-rose-500 to-pink-600',
  placeholder: 'Enter your email address',
  buttonText: 'Subscribe',
  benefits: [
    'Early access to new features',
    'Expert timing tips and tricks',
    'Exclusive subscriber-only content',
    'Special offers and discounts'
  ]
}

const downloadContent = {
  title: 'Transform Your Timing Today',
  description: 'Join thousands of satisfied users and experience the perfect blend of beauty and functionality',
  backgroundClass: 'bg-gradient-to-br from-orange-500 via-rose-500 to-pink-600',
  appImage: '/intervl-preview.png',
  features: [
    'Free 7-day trial with all premium features',
    'Regular updates with new features',
    'Dedicated customer support',
    '30-day money-back guarantee'
  ],
  storeButtons: [
    {
      text: 'Download on App Store',
      href: '#',
      icon: '/app-store.svg',
      class: 'bg-black hover:bg-gray-800 text-white px-6 py-3 rounded-lg flex items-center space-x-2 transform hover:scale-105 transition-all duration-200'
    }
  ],
  rating: {
    stars: 4.9,
    count: '10,000+',
    text: 'Based on App Store reviews'
  }
}
</script>