<script setup lang="ts">
import { Apple, Timer, Shield, Smartphone } from 'lucide-vue-next';

const features = [
  {
    icon: Timer,
    title: 'Precise Timing',
    description: 'Accurate to the millisecond for your critical training needs'
  },
  {
    icon: Shield,
    title: 'Privacy First',
    description: 'No account required, your data stays on your device'
  },
  // {
  //   icon: Smartphone,
  //   title: 'iOS Optimized',
  //   description: 'Native iOS app designed for optimal performance'
  // }
];
</script>

<template>
  <section id="download" class="bg-[#488FF2] text-white py-20">
    <div class="container mx-auto px-6">
      <div class="text-center mb-16">
        <h2 class="text-4xl font-bold mb-4">Download intervl Today</h2>
        <p class="text-xl opacity-90">Available exclusively on the App Store</p>
      </div>

      <div class="flex flex-col lg:flex-row items-center justify-between gap-12">
        <div class="lg:w-1/2">
          <div class="grid sm:grid-cols-2 lg:grid-cols-2 gap-8">
            <div v-for="feature in features" :key="feature.title"
              class="bg-white/10 backdrop-blur-lg p-6 rounded-xl w-full">
              <component :is="feature.icon" class="w-8 h-8 mb-4" />
              <h3 class="text-lg font-semibold mb-2">{{ feature.title }}</h3>
              <p class="opacity-90 text-sm">{{ feature.description }}</p>
            </div>
          </div>
        </div>

        <div class="lg:w-1/2 text-center lg:text-left">
          <h3 class="text-3xl font-bold mb-6">Ready to Transform Your Training?</h3>
          <p class="text-lg mb-8 opacity-90">
            Join thousands of athletes and trainers who have already elevated their
            training experience with intervl.
          </p>
          <a href="https://apps.apple.com/app/intervl-timer" target="_blank" rel="noopener noreferrer">
            <img src="/images/app-store.svg" alt="Download on the App Store" class="h-16 hover:opacity-90 transition" />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>